import * as React from 'react';
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";

export default function ContactInfo() {
  return (
    <div className="relative overflow-hidden py-10 px-6 bg-purple-900 sm:px-10 xl:p-12">
      <div
        className="absolute inset-0 pointer-events-none sm:hidden"
        aria-hidden="true"
      >
        <svg
          className="absolute inset-0 w-full h-full"
          width={343}
          height={388}
          viewBox="0 0 343 388"
          fill="none"
          preserveAspectRatio="xMidYMid slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
            fill="url(#linear1)"
            fillOpacity=".1"
          />
          <defs>
            <linearGradient
              x1="254.553"
              y1="107.554"
              x2="961.66"
              y2="814.66"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#fff" />
              <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
        aria-hidden="true"
      >
        <svg
          className="absolute inset-0 w-full h-full"
          width={359}
          height={339}
          viewBox="0 0 359 339"
          fill="none"
          preserveAspectRatio="xMidYMid slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
            fill="url(#linear2)"
            fillOpacity=".1"
          />
          <defs>
            <linearGradient
              x1="192.553"
              y1="28.553"
              x2="899.66"
              y2="735.66"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#fff" />
              <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
        aria-hidden="true"
      >
        <svg
          className="absolute inset-0 w-full h-full"
          width={160}
          height={678}
          viewBox="0 0 160 678"
          fill="none"
          preserveAspectRatio="xMidYMid slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
            fill="url(#linear3)"
            fillOpacity=".1"
          />
          <defs>
            <linearGradient
              x1="192.553"
              y1="325.553"
              x2="899.66"
              y2="1032.66"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#fff" />
              <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <h3 className="text-lg font-medium text-white">Contact information</h3>
      <p className="mt-6 text-base text-purple-50 max-w-3xl">
        <span className="block">Hyperfluid Solutions Corp.</span>
        <span className="block"># 348</span>
        <span className="block">1735 E Carson St</span>
        <span className="block">Pittsburgh, PA, 15203</span>
      </p>
      <dl className="mt-8 space-y-6">
        <dt>
          <span className="sr-only">Phone number</span>
        </dt>
        <dd className="flex text-base text-purple-50">
          <PhoneIcon
            className="flex-shrink-0 w-6 h-6 text-purple-200"
            aria-hidden="true"
          />
          <span className="ml-3">
            <a className="light" href="tel:+14126261556">
              +1 (412) 626-1556
            </a>
          </span>
        </dd>
        <dt>
          <span className="sr-only">Email</span>
        </dt>
        <dd className="flex text-base text-purple-50">
          <MailIcon
            className="flex-shrink-0 w-6 h-6 text-purple-200"
            aria-hidden="true"
          />
          <span className="ml-3">
            <a
              className="hover:text-white"
              href="mailto:hello@hyperfluidsolutions.com"
            >
              hello@hyperfluidsolutions.com
            </a>
          </span>
        </dd>
      </dl>
      <ul className="mt-8 flex space-x-12">
        <li>
          <a
            className="text-purple-200 hover:text-purple-100"
            href="https://www.linkedin.com/company/hyperfluid-solutions"
          >
            <span className="sr-only">Twitter</span>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              aria-hidden="true"
            >
              <path
                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                fill="currentColor"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            className="text-purple-200 hover:text-purple-100"
            href="https://github.com/hyperfluid-solutions"
          >
            <span className="sr-only">GitHub</span>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              aria-hidden="true"
            >
              <path
                d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
                fill="currentColor"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            className="text-purple-200 hover:text-purple-100"
            href="https://www.facebook.com/hyperfluidsolutions"
          >
            <span className="sr-only">Facebook</span>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              aria-hidden="true"
            >
              <path
                d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                fill="currentColor"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
}

import * as React from "react";
import ContactForm from "./contactform";
import ContactFormTy from "./contactformty";
import ContactInfo from "./contactinfo";
import ContactTypes from "./contacttypes";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: 0,
      contactType: null,
      formData: null,
    };

    this.selectContactType = this.selectContactType.bind(this);
    this.resetContactFormState = this.resetContactFormState.bind(this);
    this.completeContactFormMessage = this.completeContactFormMessage.bind(this);
  }

  selectContactType(contactType) {
    this.setState({
      formState: 1,
      contactType,
    });
  }

  resetContactFormState() {
    this.setState({
      formState: 0,
    });
  }

  completeContactFormMessage(formData) {
    this.setState({
      formState: 2,
      formData,
    });
  }

  render() {
    const contactArea = {
      0: (
        <ContactTypes onContactTypeSelect={this.selectContactType} />
      ),
      1: (
        <ContactForm formType={this.state.contactType} formBack={this.resetContactFormState} formComplete={this.completeContactFormMessage} />
      ),
      2: (
        <ContactFormTy formData={this.state.formData} />
      )
    }
    return (
      <div
        className="relative bg-white shadow-xl"
        {...(!!this.props.id ? { id: this.props.id } : {})}
      >
        <h2 className="sr-only">Contact us</h2>

        <div className="grid grid-cols-1 lg:grid-cols-3">
          {/* Contact information */}
          <ContactInfo />

          {/* Contact form */}
          {contactArea[this.state.formState]}
        </div>
      </div>
    );
  }
}

import * as React from "react";

export default function ContactFormTy() {
  return (
    <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
      <h3 className="text-lg font-medium text-gray-900">
        Thanks for being awesome!
      </h3>
      <p className="py-2">
        We have received your message and would like to thank you for writing to
        us. If your inquiry is urgent, please use the telephone number listed
        to the left to talk to one of our staff members.
      </p>
      <p className="py-2">Otherwise, we will reply by email as soon as possible.</p>
      <p className="py-2">Talk to you soon, {`${this.props.formData.name}`}.</p>
    </div>
  );
}

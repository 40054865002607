import * as React from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";

const contactReasons = {
  buildApp: {
    title: "What kind of app are you building?",
  },
  softwareDelivery: {
    title: "Tell us about your product",
  },
  sayHi: {
    title: "Send us a message",
  },
};
export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      name: "",
      message: "",
      email: "",
      phone: "",
      formstarted: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formComplete = this.formComplete.bind(this);
    this.formBack = this.formBack.bind(this);

    if (this.props) {
      console.log(this.props);
    }
  }

  formBack() {
    this.props.formBack();
  }

  formComplete(formData) {
      this.props.formComplete(formData);
  }

  handleInputChange(event) {
    window.gtag && window.gtag("event", "contact_form_started");
    if (this.state.formstarted === false) {
      this.setState({
        formstarted: new Date(),
      });
    }
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      disabled: true,
    });

    const { formstarted } = this.state;
    const formData = {
      name: this.state.name,
      message: this.state.message,
      email: this.state.email,
      phone: this.state.phone,
      contact_type: this.props.formType,
    };
    fetch("https://api.hyperfluidsolutions.com/v1/contactform", {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        console.log(response);
        window.gtag &&
          window.gtag("event", "contact_form_submitted", {
            value:
              formstarted instanceof Date &&
              formstarted.toString() !== "Invalid Date"
                ? new Date() - formstarted
                : -1,
          });
          this.setState({
            disabled: false,
          });
          this.formComplete(formData);
      })
      .catch((ex) => {
        window.gtag &&
          window.gtag("event", "contact_form_failed", {
            event_category: "failure",
            event_label: JSON.stringify(ex),
          });
          this.setState({
            disabled: false,
          });
      });

    window.gtag && window.gtag("event", "generate_lead", ...formData);
  }

  render() {
    return (
      <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
        <button
          onClick={this.props.formBack}
          className="bg-white hover:bg-gray-100 text-gray-800 font-bold my-6 py-2 px-4 rounded inline-flex items-center border border-gray-400 rounded shadow"
        >
          <ChevronLeftIcon
            className="h-5 w-5 inline-block text-gray-400"
            aria-hidden="true"
          />
          go back
        </button>
        <h3 className="text-lg font-medium text-gray-900">{`${
          contactReasons[this.props.formType].title
        }`}</h3>
        <form
          onSubmit={this.handleSubmit}
          className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
        >
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-900"
            >
              *Name/Nickname
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="name"
                required
                autoComplete="name"
                value={this.state["name"]}
                onChange={this.handleInputChange}
                readOnly={this.state.disabled}
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-900"
            >
              *Email
            </label>
            <div className="mt-1">
              <input
                name="email"
                type="email"
                required
                autoComplete="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                readOnly={this.state.disabled}
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-900"
              >
                Phone
              </label>
            </div>
            <div className="mt-1">
              <input
                type="text"
                name="phone"
                autoComplete="tel"
                value={this.state.phone}
                onChange={this.handleInputChange}
                readOnly={this.state.disabled}
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
                aria-describedby="phone-optional"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <div className="flex justify-between">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-900"
              >
                *Message
              </label>
              <span className="text-sm text-gray-500">Max. 500 characters</span>
            </div>
            <div className="mt-1">
              <textarea
                name="message"
                required
                value={this.state.message}
                onChange={this.handleInputChange}
                readOnly={this.state.disabled}
                rows={4}
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-purple-500 focus:border-purple-500 border border-gray-300 rounded-md"
                aria-describedby="message-max"
              />
            </div>
          </div>
          <input type="hidden" value={this.props.formType} />
          <div className="sm:col-span-2 sm:flex sm:justify-end">
            <button
              type="submit"
              disabled={this.state.disabled}
              className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-purple-800 hover:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-700 disabled:opacity-50 disabled:cursor-wait sm:w-auto"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    );
  }
}
